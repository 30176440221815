import React, { useEffect, useState } from 'react'
import { loadingState } from 'src/atom/LoadingState'
import logo from '../../../assets/images/logo.jpg'
import { CSpinner, CButton } from '@coreui/react'
import { useRecoilState } from 'recoil'
import { useLocation } from 'react-router-dom'
import { globalStream } from 'src/atom/GlobalStream'
import { useNavigate } from 'react-router-dom'
import { verifyOTP,resendOTP } from 'src/services/Registration/RegisterationService'
import Toastify, { showToast } from 'src/utils/Tostify'
import LogoHeader from 'src/components/header/LogoHeader'

const OtpScreen = () => {
  const otpObject = {
    id: '',
    mobileOTP: '',
  }
  const [formData, setFormData] = useState(otpObject)
  const [showResendButton, setShowResendButton] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loader, enableLoader] = useRecoilState(loadingState)
  const [countdown, setCountdown] = useState(60)
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = location.state || {}
  const [validationErrors, setValidationErrors] = useState({
    mobileOTP: '',
  })

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked)
  }
  const handleResend = async () => {
    setCountdown(60)
    try {
      setShowResendButton(false)
      if (GSteam.neetRollNumber !== '') {
        await handleResendOTP(GSteam.neetRollNumber)
      }
    } catch (error) {
      showToast(error.message)
    }
}
  const handleResendOTP = async (rollno) => {
    try {
      let rollStr = rollno.toString()
      const response = await resendOTP(rollStr)
      if (response.success) {
        showToast('OTP sent successfully', 'success')
      } else {
        showToast('OTP Failure')
      }
    } catch (error) {
      showToast(error.message)
    }
  }

  const validateForm = () => {
    let errors = {}
    let isValid = true
    if (!formData.mobileOTP) {
      errors.mobileOTP = 'Please provide OTP'
      isValid = false
    }
    setValidationErrors(errors)
    return isValid
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    enableLoader(true)

    const isValid = validateForm()
    if (isValid) {
      formData.id = id
      setIsSubmitting(true)
      const response = await verifyOTP(formData)
      if (response.success) {
        setDetails(response.body)
        const det = response.body
    
        setLoading(true)
        enableLoader(false)
        setIsSubmitting(false)
        navigate('/RegistrationValidation', { state: { det } })
        // setVisible(false)
      } else {
        setIsSubmitting(false)
        // alert(response.message)
        showToast('Invalid OTP ', 'error')
      }
    }
  }

  useEffect(() => {
    let timer = null
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } else {
      setShowResendButton(true)
    }
    return () => clearTimeout(timer)
  }, [countdown])

  const verifyOtp = async () => {
    enableLoader(true)
    const fetchOtp = async () => {
      enableLoader(false)
      var response = await verifyOtp(formData)
      // console.log("response",response.body)
      if (response.success) {
        // alert('otp successfully send on your register mobile number ')
        //  enableLoader(false)

        showToast('OTP successfully send on your register mobile number ', 'success')
      } else {
        //  enableLoader(false)
        alert(response.message)
        showToast(response.message, 'error')
      }
    }
    fetchOtp()
  }
  const handleOTPChange = (e) => {
    const { value } = e.target

    if (/^[0-9]{0,6}$/.test(value) || value === '') {
      setFormData({
        ...formData,
        mobileOTP: value === '' ? '' : parseInt(value),
      })
    }
  }

  // useEffect(() => {

  //   if (!formData.rollno) {

  //     navigate('/homepage')
  //   }
  // }, [formData.neetRollNumber]);

  return (
    <>
      <div className="card">
       <LogoHeader/>
      </div>
      <div
        className="mt-2 d-flex justify-content-center align-items-center"
        style={{ fontWeight: 'bold', fontSize: '25px' }}
      >
        Online Application Form For Admission To {GSteam.counsellingName}
        {' '} Courses 2024-25
      </div>

      <div className="card ms-5 me-5 mt-3">
        <div className="card-body">
          <div className="row">
            <div className="col-12 d-flex justify-content-strt">
              <th>Instruction Details</th>
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="d-flex flex-column align-items-left">
                <label style={{ color: 'red' }}>
                  <tr>1. OTP has been Generated.</tr>
                  <tr>
                    2. Application ID and Password will be sent to your Registration email ID and mobile Number.
                  </tr>
                </label>
              </div>
              <div className="row justify-content-center">
                <div className="row justify-content-center">
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <div className="col-12  mt-3 text-center">
                      <input
                        type="text"
                        className={`form-control ${validationErrors.mobileOTP ? 'is-invalid' : ''}`}
                        id="OTP"
                        // Allow only numeric input
                        placeholder="Enter OTP"
                        value={formData.mobileOTP}
                        onChange={handleOTPChange} // Placeholder text for input
                      />
                      {validationErrors.mobileOTP && (
                        <div className="invalid-feedback">{validationErrors.mobileOTP}</div>
                      )}
                      <div className="mt-2">
                        <button
                          type="submit"
                          className="mt-2 btn btn-sm btn-success text-white"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                        {showResendButton && (
                          <button
                            className="mt-2 ms-2 btn btn-sm btn-danger text-white "
                            type="button"
                            onClick={handleResend}
                          >
                            Resend
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mt-2">
                  <div className="col-sm-4 d-flex justify-content-center text-uppercase">
                    <p>Resend Otp Code After: {countdown} seconds</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toastify />
    </>
  )
}

export default OtpScreen
